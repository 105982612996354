window.addEventListener('load', () => {
    particlesJS('particles-js-faq', {
        'particles': {
            'number': {'value': 20, 'density': {'enable': true, 'value_area': 800}},
            'color': {'value': '#877902'},
            'opacity': {
                'value': 1,
                'random': false,
                'anim': {'enable': false, 'speed': 1, 'opacity_min': 0.1, 'sync': false},
            },
            'maxParticles': 100,
            'size': {
                'value': 0,
                'random': true,
                'anim': {'enable': false, 'speed': 35, 'size_min': 0.1, 'sync': false},
            },
            'line_linked': {'enable': true, 'distance': 260, 'color': '#877902', 'opacity': 0.4, 'width': 0.9},
            'move': {
                'enable': true,
                'speed': 6,
                'direction': 'none',
                'random': false,
                'straight': false,
                'out_mode': 'out',
                'bounce': false,
                'attract': {'enable': false, 'rotateX': 600, 'rotateY': 1200},
            },
        },
        'interactivity': {
            'detect_on': 'canvas',
            'events': {
                'onhover': {'enable': true, 'mode': 'repulse'},
                'onclick': {'enable': false, 'mode': 'push'},
                'resize': true,
            },
            'modes': {
                'grab': {'distance': 400, 'line_linked': {'opacity': 1}},
                'bubble': {'distance': 400, 'size': 40, 'duration': 2, 'opacity': 8, 'speed': 3},
                'repulse': {'distance': 200, 'duration': 0.4},
                'push': {'particles_nb': 4},
                'remove': {'particles_nb': 2},
            },
        },
        'retina_detect': true,
    });


    particlesJS('particles-js', {
        'particles': {
            'number': {'value': 24, 'density': {'enable': true, 'value_area': 800}},
            'color': {'value': '#ffe500'},
            'maxParticles': 100,
            'opacity': {
                'value': 0.7,
                'random': false,
                'anim': {'enable': false, 'speed': 1, 'opacity_min': 0.1, 'sync': false},
            },
            'size': {
                'value': 0,
                'random': true,
                'anim': {'enable': false, 'speed': 35, 'size_min': 0.1, 'sync': false},
            },
            'line_linked': {'enable': true, 'distance': 260, 'color': '#ffe500', 'opacity': 0.4, 'width': 0.9},
            'move': {
                'enable': true,
                'speed': 6,
                'direction': 'none',
                'random': false,
                'straight': false,
                'out_mode': 'out',
                'bounce': false,
                'attract': {'enable': false, 'rotateX': 600, 'rotateY': 1200},
            },
        },
        'interactivity': {
            'detect_on': 'canvas',
            'events': {
                'onhover': {'enable': true, 'mode': 'repulse'},
                'onclick': {'enable': false, 'mode': 'push'},
                'resize': true,
            },
            'modes': {
                'grab': {'distance': 400, 'line_linked': {'opacity': 1}},
                'bubble': {'distance': 400, 'size': 40, 'duration': 2, 'opacity': 8, 'speed': 3},
                'repulse': {'distance': 200, 'duration': 0.4},
                'push': {'particles_nb': 4},
                'remove': {'particles_nb': 2},
            },
        },
        'retina_detect': true,
    });


    particlesJS('particles-js-contacts', {
        'particles': {
            'number': {'value': 20, 'density': {'enable': true, 'value_area': 800}},
            'color': {'value': '#ffe500'},
            'maxParticles': 100,
            'opacity': {
                'value': 0.7,
                'random': false,
                'anim': {'enable': false, 'speed': 1, 'opacity_min': 0.1, 'sync': false},
            },
            'size': {
                'value': 0,
                'random': true,
                'anim': {'enable': false, 'speed': 35, 'size_min': 0.1, 'sync': false},
            },
            'line_linked': {'enable': true, 'distance': 260, 'color': '#ffe500', 'opacity': 0.4, 'width': 0.9},
            'move': {
                'enable': true,
                'speed': 6,
                'direction': 'none',
                'random': false,
                'straight': false,
                'out_mode': 'out',
                'bounce': false,
                'attract': {'enable': false, 'rotateX': 600, 'rotateY': 1200},
            },
        },
        'interactivity': {
            'detect_on': 'canvas',
            'events': {
                'onhover': {'enable': true, 'mode': 'repulse'},
                'onclick': {'enable': false, 'mode': 'push'},
                'resize': true,
            },
            'modes': {
                'grab': {'distance': 400, 'line_linked': {'opacity': 1}},
                'bubble': {'distance': 400, 'size': 40, 'duration': 2, 'opacity': 8, 'speed': 3},
                'repulse': {'distance': 200, 'duration': 0.4},
                'push': {'particles_nb': 4},
                'remove': {'particles_nb': 2},
            },
        },
        'retina_detect': true,
    });

    particlesJS('particles-js-advantages', {
        'particles': {
            'number': {'value': 26, 'density': {'enable': true, 'value_area': 800}},
            'color': {'value': '#ffe500'},
            'opacity': {
                'value': 0.7,
                'random': false,
                'anim': {'enable': false, 'speed': 1, 'opacity_min': 0.1, 'sync': false},
            },
            'maxParticles': 100,
            'size': {
                'value': 0,
                'random': true,
                'anim': {'enable': false, 'speed': 35, 'size_min': 0.1, 'sync': false},
            },
            'line_linked': {'enable': true, 'distance': 260, 'color': '#ffe500', 'opacity': 0.4, 'width': 0.9},
            'move': {
                'enable': true,
                'speed': 6,
                'direction': 'none',
                'random': false,
                'straight': false,
                'out_mode': 'out',
                'bounce': false,
                'attract': {'enable': false, 'rotateX': 600, 'rotateY': 1200},
            },
        },
        'interactivity': {
            'detect_on': 'canvas',
            'events': {
                'onhover': {'enable': true, 'mode': 'repulse'},
                'onclick': {'enable': false, 'mode': 'push'},
                'resize': true,
            },
            'modes': {
                'grab': {'distance': 400, 'line_linked': {'opacity': 1}},
                'bubble': {'distance': 400, 'size': 40, 'duration': 2, 'opacity': 8, 'speed': 3},
                'repulse': {'distance': 200, 'duration': 0.4},
                'push': {'particles_nb': 4},
                'remove': {'particles_nb': 2},
            },
        },
        'retina_detect': true,
    });

});
